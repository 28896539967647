/* eslint-disable prettier/prettier */
export default [
  '<table class="calendar-table">',
    '<thead>',
      '<tr>',
        '<td class="calendar-prev"><a href="#"><i class="pf pf-chevron-left"></i><span class="txtAssistive">&#8592;</span></a></td>',
        '<td colspan="5" class="calendar-title"><span class="calendar-month"></span> <span class="calendar-year"></span></td>',
        '<td class="calendar-next"><a href="#"><i class="pf pf-chevron-right"></i><span class="txtAssistive">&#8594;</span></a></td>',
      '</tr>',
    '</thead>',
    '<tbody></tbody>',
  '</table>'
].join('');
