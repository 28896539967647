import deepKeys from './deep-keys';
import _featureTests from './feature-tests';
import _raf from './raf';

var raf = _raf.bind(window);
var featureTests = deepKeys(_featureTests).map(function (t) {
  return t.join('.');
});

export { default as debounce } from './debounce';
export { default as empty } from './empty';
export { default as getScrollbarSize } from './get-scrollbar-size';
export { default as hasFeature } from './has-feature';
export { default as isAndroidStockBrowser } from './is-android-stock-browser';
export { default as isMobile } from './is-mobile';
export { default as isStyleSupported } from './is-style-supported';
export { default as loadCss } from './load-css';
export { default as loadSvg } from './load-svg';
export { default as rafThrottle } from './raf-throttle';
export { default as smoothScroll } from './smooth-scroll';
export { featureTests, raf };
