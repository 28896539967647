import eventListener from 'component-event';
import forEach from 'mout/array/forEach';
import map from 'mout/array/map';

export default function clickOutside(elements, fn) {
  var self = {
    on,
    off,
    isClicked
  };
  var clickedEl;
  var handlers;

  function stop(e) {
    clickedEl = this;
    e.stopPropagation();
  }

  function pass() {
    clickedEl = undefined;
    fn();
  }

  function isClicked(el) {
    return el === clickedEl;
  }

  function on() {
    handlers = map(elements, function (el) {
      return eventListener.bind(el, 'mousedown', stop.bind(el));
    });

    eventListener.bind(document.documentElement, 'mousedown', pass);

    return self;
  }

  function off() {
    eventListener.unbind(document.documentElement, 'mousedown', pass);

    forEach(elements, function (el, i) {
      eventListener.unbind(el, 'mousedown', handlers[i]);
    });

    return self;
  }

  return self;
}
