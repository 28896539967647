import classes from 'component-classes';
import eventListener from 'component-event';

function updateToggleStates(inputEl) {
  var inputName = inputEl.name;
  if (
    !(inputName && document.querySelector('[data-toggle="' + inputName + '"]'))
  ) {
    return; // Only run if toggles exists
  }

  var toggles = Array.prototype.slice.call(
    document.querySelectorAll('[data-toggle="' + inputName + '"]')
  ); // Get toggles
  var inputs = document.querySelectorAll('input[name="' + inputName + '"]'); // Get inputs
  var activeToggles = toggles.filter(function (_toggle, i) {
    try {
      var checked = inputs[i].checked;
    } catch (e) {
      console.error(
        'Entries with data-toggle="' +
          inputs[0].name +
          '" has less entries than input buttons with that name.',
        e.stack
      );
    }
    return checked;
  });

  activeToggles.forEach(function (activeToggle) {
    classes(activeToggle).add('is-active');
  });
  toggles
    .filter(function (toggle) {
      return activeToggles.indexOf(toggle) < 0;
    })
    .forEach(function (toggle) {
      classes(toggle).remove('is-active');
    });
}

export var init = function init() {
  eventListener.bind(
    document.documentElement,
    'input',
    function handleInput(e) {
      updateToggleStates(e.target);
    }
  );

  Array.prototype.forEach.call(
    document.querySelectorAll('input:checked'),
    updateToggleStates
  );
};
