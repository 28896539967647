/**
 * Throttle a function by requestAnimationFrame
 */

import requestAnimationFrame from './raf';

export default function rafThrottle(fn) {
  var busy = false;

  return function () {
    if (busy) {
      return;
    }

    busy = true;

    fn.apply(this, arguments);

    requestAnimationFrame(function () {
      busy = false;
    });
  };
}
