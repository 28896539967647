/**
 * Conditional state classes
 */

import classes from 'component-classes';

export default function state(elem, className, active) {
  if (typeof active === 'undefined') {
    return classes(elem).has('is-' + className);
  }

  var service = active === 'toggle' ? 'toggle' : active ? 'add' : 'remove';

  classes(elem)[service]('is-' + className);

  return Boolean(active);
}
