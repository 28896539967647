import classes from 'component-classes';
import deepMixIn from 'mout/object/deepMixIn';

import Group from './group';
import Panel from './panel';
import Trigger from './trigger';

var reduce = function reduce(arr, fnc, initial) {
  return Array.prototype.reduce.call(arr, fnc, initial);
};

var DEFAULTS = {
  rootSelector: false,
  subNavClass: 'drillDownNav-sub',
  subNavContentClass: 'drillDownNav-subContent',
  sectionClass: 'drillDownNav-section',
  sectionsWrapperClass: 'drillDownNav-sectionsWrapper',
  subNavToggleSelector: '.drillDownNav-trigger'
};

export default function createDrillDownNav(el, options) {
  return new DrillDownNav(el, options);
}

function DrillDownNav(el, options) {
  var _this = this;

  this.el = el;
  this.options = deepMixIn({}, DEFAULTS, options);

  var parentNode = this.options.rootSelector
    ? this.el.querySelector(this.options.rootSelector)
    : this.el;

  var sectionsWrapperNode = this.el.querySelector(
    '.' + this.options.sectionsWrapperClass
  );

  var group = (this.rootGroup = new Group({
    state: 'open',
    afterStateChange: function afterStateChange(_group, hasActivePanel) {
      if (sectionsWrapperNode != null) {
        classes(sectionsWrapperNode).toggle('no-scroll', hasActivePanel);
      }
    }
  }));

  this.panels = getSectionNodes(parentNode, this.options.sectionClass).reduce(
    function (flattenedPanels, sectionNode) {
      var section = new Section(sectionNode, group, null, _this.options);
      flattenedPanels.push(section.panel);

      if (section.subPanels.length > 0) {
        flattenedPanels.push.apply(flattenedPanels, section.subPanels);
      }

      return flattenedPanels;
    },
    []
  );
}

DrillDownNav.prototype.close = function close(group) {
  group = group !== undefined ? group : this.rootGroup;

  if (group.activePanel) {
    if (group.activePanel.options.subGroup) {
      this.close(group.activePanel.options.subGroup);
    }
    group.removeActivePanel();
  }
};

DrillDownNav.prototype.openPanel = function openPanel(panel) {
  this.close(); // close all groups

  (function open(panel) {
    if (panel.group) {
      panel.group.setActivePanel(panel);
      panel.setState(true);

      var parentPanel = panel.options.parent;
      if (parentPanel != null) {
        open(parentPanel);
        parentPanel.setIsVisible(false);
      }
    }
  })(panel);
};

function Section(node, group, parentPanel, options) {
  this.header = node.querySelector('header');

  var nav = node.querySelector('.' + options.subNavClass);
  var navContent = nav.querySelector('.' + options.subNavContentClass);
  var navHeader = getChildHeaderOf(nav);

  if (navHeader != null) {
    this.navHeader = navHeader;
  } else {
    this.navHeader = this.header.cloneNode(true);
    nav.insertBefore(this.navHeader, nav.firstChild);
  }

  this.headerTrigger =
    options.subNavToggleSelector === 'header'
      ? this.header
      : this.header.querySelector(options.subNavToggleSelector);
  this.navHeaderTrigger =
    options.subNavToggleSelector === 'header'
      ? this.navHeader
      : this.navHeader.querySelector(options.subNavToggleSelector);

  var panel = (this.panel = new Panel(node, {
    group,
    parent: parentPanel
  }));

  new Trigger(this.headerTrigger, { panel }); // eslint-disable-line no-new
  new Trigger(this.navHeaderTrigger, { panel }); // eslint-disable-line no-new

  var subSections = getSectionNodes(navContent, options.sectionClass);

  if (subSections.length) {
    var subGroup = (panel.options.subGroup = new Group({
      state: 'open',
      parent: group
    }));

    subSections.forEach(function (node) {
      return new Section(node, subGroup, panel, options);
    });

    this.subPanels = subGroup.panels;
  } else {
    this.subPanels = [];
  }
}

function getSectionNodes(node, sectionClass) {
  return reduce(
    node.children,
    function sectionNodesReducer(sectionNodes, node) {
      if (node.tagName.toLowerCase() === 'section') {
        if (classes(node).has(sectionClass)) {
          sectionNodes.push(node);
          return sectionNodes;
        }

        sectionNodes = reduce(node.children, sectionNodesReducer, sectionNodes);
      }

      return sectionNodes;
    },
    []
  );
}

function getChildHeaderOf(node) {
  var children = node.children;
  var child;
  var foundHeader;
  var i;

  if (children) {
    for (i = 0; i < children.length; i++) {
      child = children[i];

      if (classes(child).has('drillDownNav-header')) {
        foundHeader = child;
        break;
      }
    }
  }

  return foundHeader;
}
