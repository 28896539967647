import classes from 'component-classes';
import domify from 'domify';
import Emitter from 'emitter-component';
import inheritPrototype from 'mout/lang/inheritPrototype';
import deepMixIn from 'mout/object/deepMixIn';
import merge from 'mout/object/merge';

import delay from '../utils/delay';
import setImmediateShim from '../utils/set-immediate';
import transitEnd from '../utils/transit-end';
import uuid from '../utils/uuid';

var document = window.document;

var DEFAULTS = {
  position: 'bottomLeft',
  animation: 'slideUp',
  duration: 5000,
  template: '<div class="toaster-item"></div>',
  dismissLabel: 'Dismiss',
  closable: false
};

var TOAST_TYPES = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success'
};

export default function createToaster(options) {
  return new Toaster(options);
}

createToaster.TOAST_TYPES = TOAST_TYPES;

function Toaster(options) {
  Emitter.call(this);

  options = this.options = deepMixIn({}, DEFAULTS, options);

  this._items = [];
  this._ids = [];
  this._wrapper = createWrapper();
  this._wrapper.className = 'toaster toaster--' + options.position;

  if (options.wrapperClass) {
    this._wrapper.className += ' ' + options.wrapperClass;
  }

  this._closeHandler = function handleClose(e) {
    this.hide(e.currentTarget.parentNode);
  }.bind(this);
}

inheritPrototype(Toaster, Emitter);

/**
 * Gets the list of toast items
 */
Toaster.prototype.getElements = function () {
  return this._items;
};

Toaster.prototype.getElementsById = function (id) {
  var toastElements = this._items;
  return this._ids.reduce(function idsReducer(toastElementsById, id, idx) {
    toastElementsById[id] = toastElements[idx];
    return toastElementsById;
  }, Object.create(null));
};

Toaster.prototype.create = function (content, options) {
  options = merge(this.options, options || {});

  var self = this;

  var template;
  if (options.type) {
    template = getToastTemplate(options);
  } else {
    template = options.template;
  }

  var itemId = options.id || uuid();
  var item = domify(template);
  var itemClasses = classes(item);

  if (typeof content === 'string') {
    if (options.closable) {
      var contentEl = document.createElement('div');
      contentEl.className = 'mRm';
      contentEl.innerHTML = content;
      item.appendChild(contentEl);
    } else {
      item.innerHTML = content;
    }
  } else {
    item.appendChild(content);
  }

  if (options.closable) {
    var closeTrigger = item.querySelector('.js-dismissWaveToast');
    if (closeTrigger !== null) {
      closeTrigger.addEventListener('click', this._closeHandler);
    }
  }

  // Add `.toaster-item` class if it's missing in the template
  if (!itemClasses.has('toaster-item')) {
    itemClasses.add('toaster-item');
  }

  itemClasses.add('toaster-item--' + options.animation).add('is-hidden');

  this._wrapper.insertBefore(item, this._wrapper.firstChild);

  this._items.push(item);
  this._ids.push(itemId);

  // Set a timeout, so transition can trigger
  delay(15)(function () {
    self.show(item);
  });

  if (options.duration) {
    delay(parseInt(options.duration, 10) + 15)(function () {
      self.hide(item);
    });
  }
};

/**
 * Hides the toast item after the duration is up
 * Also removes the element from the DOM once the transition is complete
 */
Toaster.prototype.hide = function (element) {
  // pass the element
  this.emit('hiding', element);

  classes(element).remove('is-shown').add('is-hidden');

  var closeTrigger = element.querySelector('.js-dismissWaveToast');
  if (closeTrigger !== null) {
    closeTrigger.removeEventListener('click', this._closeHandler);
  }

  transitEnd(
    element,
    function () {
      var idx = this._items.indexOf(element);

      element.parentNode.removeChild(element);

      if (idx !== -1) {
        this._items.splice(idx, 1);
        this._ids.splice(idx, 1);
      }

      this.emit('hide');
    }.bind(this)
  );
};

/**
 * Reveals the toast item after it has been placed in the container
 */
Toaster.prototype.show = function (element) {
  this.emit('showing', element);

  setImmediateShim(function () {
    classes(element).remove('is-hidden').add('is-shown');
  });

  transitEnd(
    element,
    function () {
      this.emit('show', element);
    }.bind(this)
  );
};

function createWrapper() {
  return document.body.appendChild(document.createElement('div'));
}

function getToastTemplate(options) {
  var template;
  var closeButtonMarkup = '';
  var itemClasses =
    'toaster-item toaster-item--elevated fill-white notification notification--' +
    String(options.type);

  if (options.closable) {
    closeButtonMarkup = [
      '<button type="button" class="notification-dismissBtn accent120 posA r0 t0 mAn btn btn--ghost btn--icon btn--small btn--fullRounded js-dismissWaveToast" aria-label="' +
        options.dismissLabel +
        '">',
      '<i class="pf pf-close" aria-hidden="true"></i>',
      '</button>'
    ].join('');
    itemClasses += ' pRxl';
  }

  switch (options.type) {
    case TOAST_TYPES.INFO:
      template =
        '<div class="' +
        itemClasses +
        '" aria-live="polite">' +
        closeButtonMarkup +
        '</div>';
      break;
    case TOAST_TYPES.WARNING:
      template =
        '<div class="' +
        itemClasses +
        '" role="alert">' +
        closeButtonMarkup +
        '</div>';
      break;
    case TOAST_TYPES.ERROR:
      template =
        '<div class="' +
        itemClasses +
        '" role="alert">' +
        closeButtonMarkup +
        '</div>';
      break;
    case TOAST_TYPES.SUCCESS:
      template =
        '<div class="' +
        itemClasses +
        '" aria-live="polite">' +
        closeButtonMarkup +
        '</div>';
      break;
  }

  return template || options.template;
}
