/**
 * Execute callback when `DOMContentLoaded` fires for `document`,
 * or immediately if called afterwards
 */

export default function domReady(handler) {
  if (
    /complete|loaded|interactive/.test(document.readyState) &&
    document.body
  ) {
    handler();
  } else {
    document.addEventListener('DOMContentLoaded', handler, false);
  }
}
