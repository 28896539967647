/* eslint-disable prettier/prettier */
export default [
  '<div class="modal modal--is-hidden" tabindex="0">',
    '<div class="modal-inner">',
      '<div class="modal-container">',
        '<div class="modal-content"></div>',
      '</div>',
    '</div>',
  '</div>'
].join('');
