var transitions = {
  transition: 'transitionend',
  OTransition: 'oTransitionEnd',
  MozTransition: 'transitionend',
  WebkitTransition: 'webkitTransitionEnd'
};
var memo;

export default function whichTransitionEvent() {
  if (memo === undefined) {
    var el = document.createElement('fakeelement');

    for (var t in transitions) {
      if (el.style[t] !== undefined) {
        memo = transitions[t];
        return memo;
      }
    }
  }

  return memo;
}
