import { init as initInputToggles } from './legacy/input-toggles';
import Menu from './modules/menu';
import { listen as listenForModals } from './modules/modal/modal';
import Toggle from './modules/toggle';
import domReady from './utils/dom-ready';
import hasFeature from './utils/has-feature';
import isStyleSupported from './utils/is-style-supported';
import { init as initStubs } from './utils/stubs';

export default function initWave(options) {
  options = options || {};

  // Cutting the mustard test - check if this web browser has the basic features
  // we need
  if (
    !(
      hasFeature('querySelector') &&
      hasFeature('addEventListener') &&
      Array.prototype.forEach
    )
  ) {
    throw new Error('Browser unsupported. Please try a newer web browser.');
  }

  initStubs();

  // Polyfill to support modern functionality in IE11
  if (!('remove' in Element.prototype)) {
    Element.prototype.remove = function () {
      if (this.parentNode) {
        this.parentNode.removeChild(this);
      }
    };
  }

  // Append capability specific classes to the root element
  (function (docEl) {
    docEl.className = docEl.className.replace('no-js', '');
    docEl.className += ' has-js';

    if (
      isStyleSupported('display', 'flex') ||
      isStyleSupported('display', '-webkit-flex') ||
      isStyleSupported('display', '-moz-flex') ||
      isStyleSupported('display', '-ms-flex')
    ) {
      docEl.className += ' has-flexbox';
    }
    if (isStyleSupported('animation-name')) {
      docEl.className += ' has-animation';
    }
    if (hasFeature('touch')) {
      docEl.className += ' has-touch';
    }
    if (hasFeature('viewportUnit')) {
      docEl.className += ' has-viewportUnit';
    }
  })(document.documentElement);

  if (options.avoidSideEffects === true) {
    return;
  }

  // Auto-instantiate some behaviours
  domReady(function () {
    listenForModals();

    // Dropdowns
    Toggle('.js-triggerDropdown', {
      toggleClass: 'dropdown--is-open'
    })
      .onClickToggleAll()
      .offClickCloseAll();

    // Collabsible panels
    Toggle('.js-triggerExpand', {
      toggleClass: 'collapsiblePanel--is-expanded'
    }).onClickToggle();

    // Side drawer
    Toggle('.js-toggleSideDrawer', {
      toggleClass: 'sideDrawer--is-open'
    })
      .onClickToggleAll()
      .offClickCloseAll();

    var collapsibleNavs = document.querySelectorAll('.nav--collapse');
    if (Menu.autoInit && collapsibleNavs.length > 0) {
      for (var i = 0; i < collapsibleNavs.length; i++) {
        var collapsibleNav = collapsibleNavs[i];
        Menu(collapsibleNav, { trigger: collapsibleNav });
      }
    }

    initInputToggles();
  });
}
