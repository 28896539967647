import classes from 'component-classes';
import eventListener from 'component-event';
import domify from 'domify';
import Emitter from 'emitter-component';
import inheritPrototype from 'mout/lang/inheritPrototype';
import deepMixIn from 'mout/object/deepMixIn';

import debounce from '../../utils/debounce';
import template from './template';

export default function createProgressBar(el, options) {
  return new ProgressBar(el, options);
}

var defaults = {
  color: 'blue100',
  htmlCaption: false,
  captionPadding: 20
};

function ProgressBar(el, options) {
  if (!el) {
    throw new Error('Wave.ProgressBar() requires a DOM element to initialize');
  }

  Emitter.call(this);

  this.options = deepMixIn({}, defaults, options);
  this.el = el;

  this._progress = 0;
  this._color = this.options.color;
  this._status = null;

  this._render();
}

inheritPrototype(ProgressBar, Emitter);

ProgressBar.prototype._hasCaption = false;

ProgressBar.prototype._render = function () {
  this.progressBar = domify(template);

  if (this.options.progressBarClass) {
    this.progressBar.className += ' ' + this.options.progressBarClass;
  }

  this.barCaption = this.progressBar.querySelector('.js-barCaption');

  if (this.options.captionClass) {
    this.barCaption.className += ' ' + this.options.captionClass;
  }

  if (this.options.caption) {
    this._setCaption(this.options.caption);
  }

  this.indicatorBar = this.progressBar.querySelector('.js-indicatorBar');
  classes(this.indicatorBar).add('fill-' + this._color);

  this.el.appendChild(this.progressBar);

  this._boundHandleResize = debounce(this._handleResize.bind(this), 100);
  eventListener.bind(window, 'resize', this._boundHandleResize);
};

/**
 * Set new progress value
 */
ProgressBar.prototype.setProgress = function (progress) {
  if (
    progress !== undefined &&
    typeof progress === 'number' &&
    progress <= 100 &&
    progress >= 0
  ) {
    // Force the layout to be computed
    // eslint-disable-next-line no-unused-expressions
    this.progressBar.offsetHeight;

    this.indicatorBar.style.width = progress + '%';

    this._progress = progress;
    this._positionCaption();

    this.emit('progress', progress);
  } else {
    throw Error('Progressbar: progress must be a number between 0 and 100');
  }

  return this;
};

/**
 * Set background fill for indicator bar
 */
ProgressBar.prototype.setFill = function (color) {
  if (color !== undefined && typeof color === 'string') {
    classes(this.indicatorBar)
      .remove('fill-' + this._color)
      .add('fill-' + color);
    this._color = color;
  } else {
    throw Error('Progressbar: background color must be a string');
  }

  return this;
};

/**
 * Set progress bar's caption
 */
ProgressBar.prototype.setCaption = function (caption) {
  this._setCaption(caption);

  return this;
};

ProgressBar.prototype._setCaption = function (caption) {
  if (this.options.htmlCaption) {
    this.barCaption.innerHTML = caption;
  } else {
    this.barCaption.textContent = caption;
  }
  this._hasCaption = true;
};

ProgressBar.prototype._positionCaption = function () {
  var progressBarWidth = this.progressBar.clientWidth;
  var barCaptionWidth = this.barCaption.clientWidth;
  var left = 0;
  var padding = this.options.captionPadding;
  var offset = (this._progress / 100) * progressBarWidth;

  if (barCaptionWidth + padding < offset) {
    left = offset - barCaptionWidth - padding / 2;
  } else {
    left = offset + padding / 2;
  }

  this.barCaption.style.left = left + 'px';
};

ProgressBar.prototype._handleResize = function () {
  this._hasCaption && this._positionCaption();
};

/**
 * Get current progress
 */
ProgressBar.prototype.getProgress = function () {
  return this._progress;
};

/**
 * Get or set the progress bar's status
 */
ProgressBar.prototype.status = function (status) {
  if (status === undefined) {
    return this._status;
  }

  var progressBarClasses = classes(this.progressBar);

  if (this._status != null) {
    progressBarClasses.remove('progressBar--' + this._status);
  }
  if (status != null) {
    progressBarClasses.add('progressBar--' + status);
  }
  this._status = status;

  this.emit('status', status);

  return this;
};

/**
 * Hides progress bar
 */
ProgressBar.prototype.hide = function () {
  classes(this.progressBar).add('progressBar--is-hidden');
  return this;
};

/**
 * Shows progress bar
 */
ProgressBar.prototype.show = function () {
  classes(this.progressBar).remove('progressBar--is-hidden');
  return this;
};
