/**
 * Clamp `month`
 */
export function clamp(month) {
  if (month > 11) {
    return 0;
  }
  if (month < 0) {
    return 11;
  }

  return month;
}
