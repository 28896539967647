/**
 * Shim for setImmediate()
 */

var _setImmediate;

if (typeof setImmediate === 'function') {
  _setImmediate = setImmediate;
} else {
  _setImmediate = function setImmediate(fn) {
    setTimeout(fn, 0);
  };
}

export default _setImmediate;
