/**
 * Transitionend event helper
 */

import eventListener from 'component-event';

import isStyleSupported from './is-style-supported';
import whichTransitionEvent from './which-transition-event';

var TRANSITION_END_EVENT = whichTransitionEvent();

export default function transitEnd(elem, callback) {
  if (isStyleSupported('transition-property')) {
    eventListener.bind(elem, TRANSITION_END_EVENT, function transitHandle() {
      eventListener.unbind(elem, TRANSITION_END_EVENT, transitHandle);
      callback(elem);
    });
  } else {
    callback(elem);
  }
}
