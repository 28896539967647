import classes from 'component-classes';
import eventListener from 'component-event';
import domify from 'domify';
import Emitter from 'emitter-component';
import inheritPrototype from 'mout/lang/inheritPrototype';

import transitEnd from '../../utils/transit-end';
import template from './template';

var document = window.document;
var body = document.body;

export default function createOverlay(options) {
  return new Overlay(options);
}

function Overlay(options) {
  Emitter.call(this);

  this.options = options || {};

  this._hidden = true;
  this._closable = this.options.closable;
  this.target = this.options.target || body;
  this.el = domify(template);

  this._handleClick = this._handleClick.bind(this);
  eventListener.bind(this.el, 'click', this._handleClick);

  if (body === this.target) {
    classes(this.el).add('overlay--fixed');
  }

  if (this.options.overlayClass) {
    this.el.className += ' ' + this.options.overlayClass;
  }

  if (this.options.zIndex) {
    this.el.style.zIndex = this.options.zIndex;
  }
}

inheritPrototype(Overlay, Emitter);

Overlay.prototype.show = function () {
  var _this = this;

  if (this._hidden === false || this._animating) {
    return;
  }

  this._hidden = false;
  this._animating = true;

  this.emit('showing');

  this.target.appendChild(this.el);

  // Force the layout to be computed
  // eslint-disable-next-line no-unused-expressions
  this.el.offsetHeight;

  classes(this.el).remove('overlay--is-hidden').add('overlay--is-shown');

  transitEnd(this.el, function () {
    _this._animating = false;
    _this.emit('show');
  });

  return this;
};

Overlay.prototype.hide = function () {
  var _this = this;

  if (this._hidden) {
    return;
  }

  this._hidden = true;
  this._animating = true;

  this.emit('hiding');

  // Force the layout to be computed
  // eslint-disable-next-line no-unused-expressions
  this.el.offsetHeight;

  classes(this.el).remove('overlay--is-shown').add('overlay--is-hidden');

  transitEnd(this.el, function () {
    _this._animating = false;
    _this.emit('hide');

    _this.el.parentNode.removeChild(_this.el);
  });

  return this;
};

/**
 * Sets a new target for overlay
 */
Overlay.prototype.setTarget = function (target) {
  this.target = target;
  this.emit('settarget');

  return this;
};

/**
 * When the overlay is clicked, emit an event so that the view that is using this overlay can choose
 * to close it
 */
Overlay.prototype._handleClick = function (e) {
  if (this._closable) {
    this.emit('click', e);
  }
};

Overlay.prototype.getElement = function () {
  return this.el;
};

Overlay.prototype.destroy = function () {
  eventListener.unbind(this.el, 'click', this._handleClick);
  this._handleClick = undefined;

  this.el = null;
  this.target = null;
};
