export var init = function init() {
  // stub console (console object is not defined in IE9)
  if (!window.console) {
    window.console = {};
    var methods = ['info', 'log', 'warn', 'debug', 'error'];
    for (var i = methods.length - 1; i > -1; i--) {
      window.console[methods[i]] = function () {};
    }
  }
};
