import unCamelCase from 'mout/string/unCamelCase';

/**
 * Helper for storing arbitrary data associated with an element.
 * The data is stored directly on the DOM elements using a Symbol and
 * garbage collection is handled by the browser
 */

var DATA_KEY = (window.Symbol && Symbol('data')) || '__PUI__DATA_KEY';
var DELIMETER = '-';

export default function data(elem, key, value) {
  if (!elem || typeof key === 'undefined') {
    return;
  }

  var data = (elem[DATA_KEY] = elem[DATA_KEY] || {});

  if (typeof value !== 'undefined') {
    return (data[key] = value);
  }

  if (typeof data[key] === 'undefined') {
    return (data[key] = elem.dataset
      ? elem.dataset[key]
      : elem.getAttribute('data-' + unCamelCase(key, DELIMETER)));
  }

  return data[key];
}
