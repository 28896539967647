import classes from 'component-classes';
import delegate from 'component-delegate';
import eventListener from 'component-event';
import domify from 'domify';
import Emitter from 'emitter-component';
import inheritPrototype from 'mout/lang/inheritPrototype';
import isString from 'mout/lang/isString';
import deepMixIn from 'mout/object/deepMixIn';
import mixIn from 'mout/object/mixIn';

import delay from '../../utils/delay';
import hasFeature from '../../utils/has-feature';
import once from '../../utils/once';
import transitEnd from '../../utils/transit-end';
import template from './template';

var document = window.document;

var hasTouch = hasFeature('touch');
var isBound;

export default function createModal(options) {
  return new Modal(options);
}

var defaults = {
  preserveDom: false,
  closable: true
};

function Modal(options) {
  this.options = deepMixIn({}, defaults, options);

  this._lastFocus = document.activeElement;

  this.body = document.body;
  this.modal = domify(template);

  this.contentHolder = this.modal.querySelector('.modal-content');

  if (this.options.contentClass) {
    this.contentHolder.className += ' ' + this.options.contentClass;
  }

  this.modalActionTrigger = null;
  this.modalCloseTrigger = null;

  if (this.options.content) {
    this.content(this.options.content, false);
  }

  this._trapFocus = hasTouch ? null : this._trapFocus.bind(this);

  if (
    this.options.modalAction &&
    typeof this.options.modalAction === 'function'
  ) {
    this.options.modalAction = this.options.modalAction.bind(this);
  }

  this._appendOnce = once(this._append, this);

  return this;
}

inheritPrototype(Modal, Emitter);

Modal.prototype.closable = function (closable) {
  closable = closable === undefined ? true : closable;

  this.options.closable = closable;
  this._closable(closable);

  return this;
};

Modal.prototype._closable = function (closable) {
  var _this = this;

  if (closable === false) {
    if (this._handleModalClick) {
      eventListener.unbind(this.modal, 'click', this._handleModalClick);
      this._handleModalClick = undefined;
    }
    if (this._handleKeydown) {
      eventListener.unbind(document, 'keydown', this._handleKeydown);
      this._handleKeydown = undefined;
    }

    return this;
  }

  /**
   * Close overlay
   */
  if (!this._handleModalClick) {
    eventListener.bind(
      this.modal,
      'click',
      (this._handleModalClick = function handleModalClick(e) {
        e.stopImmediatePropagation();

        if (e.target.className !== 'modal-container') {
          return;
        }

        _this.hide({ wasDismissed: true });
      })
    );
  }

  /**
   * Esc
   */
  if (!this._handleKeydown) {
    eventListener.bind(
      document,
      'keydown',
      (this._handleKeydown = function handleKeydown(e) {
        if (e.keyCode !== 27 || !_this._shown) {
          return;
        }

        _this.hide({ wasDismissed: true });
      })
    );
  }
};

/**
 * Trap focus
 */
Modal.prototype._trapFocus = function (e) {
  if (this.modal !== e.target && !this.modal.contains(e.target)) {
    this.modal.focus();
  }
};

Modal.prototype._append = function () {
  this.body.appendChild(this.modal);
};

Modal.prototype.show = function () {
  if (this._shown) {
    return this;
  }

  var _this = this;

  this.emit('showing');

  // Kill the scroll on the body
  classes(this.body).add('ofH');

  if (this.options.preserveDom) {
    this.modal.style.display = 'block';
    this._appendOnce();
  } else {
    this._append();
  }

  // Add action for the trigger element click event
  if (this.modalActionTrigger && this.options.modalAction) {
    this._actionClickHandler = delegate.bind(
      this.contentHolder,
      '.js-modalAction',
      'click',
      this.options.modalAction
    );
  }

  // Force the layout to be computed
  // eslint-disable-next-line no-unused-expressions
  this.modal.offsetLeft;

  classes(this.modal).remove('modal--is-hidden');

  // Account for transitions
  transitEnd(this.modal, function () {
    if (_this._isHiding) {
      return;
    }

    if (_this.options.closable) {
      _this._closable();
    }

    if (_this._trapFocus) {
      // Set focus to specific element inside active modal box
      var focus = _this.modal.querySelector('[autofocus]');
      if (focus != null) {
        focus.focus();
      } else {
        _this.modal.focus();
      }

      // prevent infinite focus loop
      eventListener.unbind(document, 'focusin', _this._trapFocus);
      eventListener.bind(document, 'focusin', _this._trapFocus);
    }

    if (_this.modalCloseTrigger) {
      _this._closeClickHandler = delegate.bind(
        _this.contentHolder,
        '.js-modalClose',
        'click',
        function handleCloseClick(e) {
          e.preventDefault();
          delegate.unbind(
            _this.contentHolder,
            'click',
            _this._closeClickHandler
          );
          _this._closeClickHandler = undefined;

          _this.hide({ wasDismissed: true });
        }
      );
    }

    _this._shown = true;
    _this.emit('show');
  });

  return this;
};

Modal.prototype.hide = function (data) {
  var _this = this;
  var eventData = mixIn({ wasDismissed: false }, data);

  this._isHiding = true;
  this.emit('hiding');

  classes(this.modal).add('modal--is-hidden');

  // delay() wrap
  // fixes https://bugzilla.mozilla.org/show_bug.cgi?id=625289
  delay(25)(function () {
    classes(_this.body).remove('ofH');
  });

  if (this.modalCloseTrigger && this._closeClickHandler) {
    delegate.unbind(this.contentHolder, 'click', this._closeClickHandler);
  }

  // Account for transitions
  transitEnd(this.modal, function (elem) {
    if (_this.modalActionTrigger && _this._actionClickHandler) {
      delegate.unbind(_this.contentHolder, 'click', _this._actionClickHandler);
    }

    if (_this.options.closable) {
      _this._closable(false);
    }

    if (_this.options.preserveDom) {
      elem.style.display = 'none';
    } else if (elem.parentNode != null) {
      elem.parentNode.removeChild(elem);
    }

    if (_this._trapFocus) {
      eventListener.unbind(document, 'focusin', _this._trapFocus);

      // Restore focus
      _this._lastFocus && _this._lastFocus.focus();
    }

    _this._shown = false;
    _this._isHiding = false;

    _this.emit('hide', eventData);
  });

  return this;
};

Modal.prototype.content = function (content, emitEvent) {
  emitEvent = emitEvent === undefined ? true : emitEvent;

  if (!content) {
    return this.contentHolder.innerHTML;
  }

  if (isString(content)) {
    this.contentHolder.innerHTML = content;
  } else {
    this.contentHolder.appendChild(content);
  }

  this.modalActionTrigger = this.modal.querySelector('.js-modalAction');
  this.modalCloseTrigger = this.modal.querySelector('.js-modalClose');

  if (emitEvent) {
    this.emit('content', content);
  }

  return this;
};

/**
 * Shorthand for accessing DOM elements within modal
 */
Modal.prototype.$ = function (selector) {
  if (this.modal) {
    return this.modal.querySelector(selector);
  }
};

/**
 * Enable the `.js-triggerModal` api
 */
export function listen() {
  if (isBound) {
    return;
  }

  isBound = true;

  return delegate.bind(
    document.body,
    '.js-triggerModal',
    'click',
    function (e) {
      e.preventDefault();

      var target = e.delegateTarget.getAttribute('data-target');

      if (!target) return;

      var targetNode = document.querySelector(target);
      var contentClass = targetNode.getAttribute('data-modal-content-class');

      if (targetNode != null) {
        var newModal = new Modal({
          content: targetNode.innerHTML,
          contentClass: contentClass || ''
        });
        newModal.show();
      }
    }
  );
}
