/**
 * Get element index in a container
 */

export default function getElementIndex(element) {
  if (!element) {
    return -1;
  }

  var index = 0;

  while ((element = element.previousElementSibling)) {
    index++;
  }

  return index;
}
