/* eslint-disable prettier/prettier */
export default [
  '<div class="multiSelect-group">',
    '<div class="multiSelect-groupHeader posR">',
      '<label class="formControl pAs dF">',
        '<input tabindex="-1" class="formControl-state multiSelect-groupCheckbox" type="checkbox" />',
        '<span class="formControl-indicator"></span>',
        '<span class="formControl-label multiSelect-groupLabel fwB"></span>',
      '</label>',
      '<div class="multiSelect-arrowButton multiSelect-groupButton">',
        '<button tabindex="-1" type="button" class="btn mAn btn--small btn--fullRounded btn--ghost btn--icon accent120">',
          '<span class="svgIcon svgIcon--stroked"><svg viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path d="M4 8l8 8 8-8" /></svg></span>',
        '</button>',
      '</div>',
    '</div>',
    '<div class="multiSelect-groupContent" role="group" aria-multiselectable="true">',
    '</div>',
  '</div>'
].join('');
