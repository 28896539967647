/* eslint-disable prettier/prettier */
export default [
  '<div class="multiSelect" role="combobox">',
    '<div class="multiSelect-labelList accent100">',
      '<input class="multiSelect-element" type="text" role="searchbox" aria-multiline="false" aria-autocomplete="list" aria-label="search">',
      '<div class="multiSelect-elementAutosize"></div>',
      '<div class="multiSelect-unselectAllButton">',
        '<button type="button" class="btn mAn btn--small btn--fullRounded btn--ghost btn--icon accent120">',
          '<span class="svgIcon svgIcon--stroked"><svg viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path d="M6 18L18 6M6 6l12 12" /></svg></span>',
        '</button>',
      '</div>',
      '<div class="multiSelect-arrowButton">',
        '<button type="button" aria-expanded="false" class="btn mAn btn--small btn--fullRounded btn--ghost btn--icon accent120">',
          '<span class="svgIcon svgIcon--stroked"><svg viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path d="M4 8l8 8 8-8" /></svg></span>',
        '</button>',
      '</div>',
    '</div>',
    '<div tabindex="-1" class="multiSelect-dropdownList black themedScrollbar scrollable-y elevationM">',
      '<div class="multiSelect-emptyState"></div>',
      '<div class="multiSelect-filteredState"></div>',
    '</div>',
  '</div>'
].join('');
