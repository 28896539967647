import matches from './matches';
import parents from './parents';

export default function closest(element, selector, shouldCheckSelf) {
  // eslint-disable-next-line eqeqeq
  if (!(element && element.nodeType == 1 && selector)) return;
  var parentElements = (shouldCheckSelf ? [element] : []).concat(
    parents(element)
  );

  for (var i = 0, parent; (parent = parentElements[i]); i++) {
    if (matches(parent, selector)) return parent;
  }
}
