import classes from 'component-classes';
import delegate from 'component-delegate';
import eventListener from 'component-event';
import Emitter from 'emitter-component';
import inheritPrototype from 'mout/lang/inheritPrototype';
import isString from 'mout/lang/isString';
import deepMixIn from 'mout/object/deepMixIn';

import closest from '../utils/closest';
import parents from '../utils/parents';
import unique from '../utils/unique';
import Overlay from './overlay/overlay';

export default function (sltor, options) {
  return new Toggle(sltor, options);
}

var defaults = {
  toggleClass: 'is-open'
};

function Toggle(sltor, options) {
  if (!sltor || !isString(sltor)) {
    throw new Error('Wave.Toggle() requires a selector string to initialize');
  }

  this.options = deepMixIn({}, defaults, options);

  this._sltor = sltor;
  this._dataTarget = null;
  this._overlay = null;

  return this;
}

inheritPrototype(Toggle, Emitter);

Toggle.prototype.hasClass = function (_self, className) {
  var check = className || this.options.toggleClass;
  var target = closest(_self, this._getDataTarget(_self));

  return classes(target).has(check);
};

Toggle.prototype._getDataTarget = function (_self) {
  this._dataTarget = _self.getAttribute('data-target') || 'li';
  return this._dataTarget;
};

Toggle.prototype._toggle = function (_self, className) {
  if (this.hasClass(_self, className)) {
    this.close(_self);
  } else {
    this.open(_self);
  }
};

Toggle.prototype._handleOverlayClick = function (_self, className) {
  this._toggle(_self, className);
};

Toggle.prototype.overlay = function (options) {
  var overlayOptions = deepMixIn({}, { closable: true }, options);
  var overlay = Overlay(overlayOptions);

  this.on('open', function () {
    overlay.show();
  });

  this.on('close', function () {
    overlay.hide();
  });

  this._overlay = overlay;
  return this;
};

Toggle.prototype.toggle = function (_self, className) {
  var overlay = this._overlay;
  if (overlay) {
    if (overlay.listeners('click').length !== 0) {
      overlay.removeListener('click');
    }

    overlay.setTarget(document.querySelector(this._getDataTarget(_self)));
    overlay.once(
      'click',
      this._handleOverlayClick.bind(this, _self, className)
    );
  }

  this._toggle(_self, className);

  this.emit('toggle');
  return this;
};

Toggle.prototype.open = function (_self) {
  var target = closest(_self, this._getDataTarget(_self));
  classes(target).add(this.options.toggleClass);

  this.emit('open');
  return this;
};

Toggle.prototype.close = function (_self) {
  var target = closest(_self, this._getDataTarget(_self));
  classes(target).remove(this.options.toggleClass);

  this.emit('close');
  return this;
};

Toggle.prototype.toggleAll = function (_self) {
  if (this.hasClass(_self)) {
    this.closeAll();
  } else {
    this.closeAll().open(_self);
  }

  this.emit('toggleAll');
  return this;
};

Toggle.prototype.closeAll = function () {
  var this_ = this;
  var nodes = document.querySelectorAll(this._sltor);
  var allParents = [];

  Array.prototype.forEach.call(nodes, function (node) {
    allParents.push.apply(allParents, parents(node));
  });

  unique(allParents).forEach(function (parent) {
    var parentClasses = classes(parent);
    if (parentClasses.has(this_.options.toggleClass)) {
      parentClasses.remove(this_.options.toggleClass);
    }
  });

  this.emit('closeAll');
  return this;
};

Toggle.prototype.onClickToggle = function () {
  var _this = this;

  this._onClickToggleHandler = delegate.bind(
    document.body,
    this._sltor,
    'click',
    function handleOnClickToggle(e) {
      e.stopPropagation();
      e.preventDefault();

      _this.toggle(e.delegateTarget);
      _this.emit('onClickToggle');
    }
  );

  return this;
};

Toggle.prototype.onClickToggleAll = function () {
  var _this = this;

  this._onClickToggleAllHandler = delegate.bind(
    document.body,
    this._sltor,
    'click',
    function handleOnClickToggleAll(e) {
      e.stopPropagation();
      e.preventDefault();

      _this.toggleAll(e.delegateTarget);
      _this.emit('onClickToggleAll');
    }
  );

  return this;
};

Toggle.prototype.offClickCloseAll = function () {
  var _this = this;

  eventListener.bind(
    document.documentElement,
    'click',
    (this._handleOffClickCloseAll = function handleOffClickCloseAll(/* e */) {
      _this.closeAll();
      _this.emit('offClickCloseAll');
    })
  );

  return this;
};

Toggle.prototype.destroy = function destroy() {
  // Unbind events
  if (this._onClickToggleHandler) {
    delegate.unbind(document.body, 'click', this._onClickToggleHandler);
    this._onClickToggleHandler = undefined;
  }
  if (this._onClickToggleAllHandler) {
    delegate.unbind(document.body, 'click', this._onClickToggleAllHandler);
    this._onClickToggleAllHandler = undefined;
  }
  if (this._handleOffClickCloseAll) {
    eventListener.unbind(
      document.documentElement,
      'click',
      this._handleOffClickCloseAll
    );
    this._handleOffClickCloseAll = undefined;
  }

  this._sltor = undefined;
  this._dataTarget = null;

  if (this._overlay != null) {
    this._overlay.destroy();
    this._overlay = null;
  }
};
