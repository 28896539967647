/* eslint-disable prettier/prettier */
export default [
  '<span class="multiSelect-item" role="listitem">',
    '<label class="formControl">',
      '<input tabindex="-1" class="formControl-state multiSelect-itemCheckbox" type="checkbox" />',
      '<span class="formControl-indicator"></span>',
      '<span class="formControl-label multiSelect-itemLabel mLs"></span>',
    '</label>',
  '</span>'
].join('');
