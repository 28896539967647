import classes from 'component-classes';
import eventListener from 'component-event';

var document = window.document;

export default function createMenu(el, options) {
  return new Menu(el, options);
}

createMenu.autoInit = true;

function Menu(el, options) {
  if (!el) {
    throw new Error('Wave.Menu() requires a DOM element to initialize');
  }

  this.el = el;
  this.trigger = options.trigger;
  this._handleClickOutside = this._handleClickOutside.bind(this);
  this._handleTriggerClick = this._handleTriggerClick.bind(this);

  if (this.trigger) {
    eventListener.bind(this.trigger, 'click', this._handleTriggerClick);
  }
}

Menu.prototype.open = function () {
  var self = this;

  classes(this.el).add('is-active');

  setTimeout(function () {
    eventListener.bind(
      document.documentElement,
      'click',
      self._handleClickOutside
    );
  }, 0);

  return this;
};

Menu.prototype.close = function () {
  eventListener.unbind(
    document.documentElement,
    'click',
    this._handleClickOutside
  );
  classes(this.el).remove('is-active');

  return this;
};

Menu.prototype.toggle = function () {
  if (this.isOpen()) {
    this.close();
  } else {
    this.open();
  }

  return this;
};

Menu.prototype.isOpen = function () {
  return classes(this.el).has('is-active');
};

Menu.prototype.destroy = function () {
  classes(this.el).remove('is-active');

  eventListener.unbind(
    document.documentElement,
    'click',
    this._handleClickOutside
  );
  eventListener.unbind(this.trigger, 'click', this._handleTriggerClick);
};

Menu.prototype._handleClickOutside = function (e) {
  if (!this.el.contains(e.target) && this.isOpen()) {
    this.close();
  }
};

Menu.prototype._handleTriggerClick = function (e) {
  if (e.target === this.trigger) {
    this.toggle();
  }
};
