/**
 * Load SVG over xhr
 */

import xhr from 'xhr';

export default function loadSvg(options, cb) {
  if (typeof options === 'string') {
    options = { uri: options };
  }

  xhr(options, function (err, resp, body) {
    if (err) {
      return cb(err);
    }

    if (!/^2/.test(resp.statusCode)) {
      return cb(new Error('http status code: ' + resp.statusCode));
    }

    var div = document.createElement('div');
    div.innerHTML = body;

    var svg = div.querySelector('svg');
    if (!svg) {
      return cb(new Error('SVG not present in resource'));
    }

    cb(null, svg);
  });
}
