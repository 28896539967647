import * as mediaQueries from '@volue/design-media-queries';
import classes from 'component-classes';
import Emitter from 'emitter-component';
import inheritPrototype from 'mout/lang/inheritPrototype';

import closest from '../utils/closest';
import rafThrottle from '../utils/raf-throttle';
import ViewportMonitor from './viewport-monitor';

inheritPrototype(SidebarNav, Emitter);

export default function createSidebarMenu(element, options) {
  return new SidebarNav(element, options);
}

function SidebarNav(element, options) {
  this.element = element;
  this.currentView = mediaQueries.mqLargeAndUp;
  this.rememberCollapsedState = options
    ? !!options.rememberCollapsedState
    : false;
  this.closeAfterItemPick = options ? !!options.closeAfterItemPick : true;
  this.rememberCollapsedStateValue = this.rememberCollapsedState
    ? window.localStorage.getItem('waveSidebarNavCollapsedState')
    : null;
  this.viewportMonitor = new ViewportMonitor();
  this.listeners = [];

  this.header = this.element.querySelector('.sidebarNav-header');
  this.headerMenu = this.element.querySelector('.sidebarNav-headerMenu');
  this.headerMenuToggle = this.element.querySelector(
    '.js-sidebarNav-headerMenuToggle'
  );
  this.headerMenuContent = this.element.querySelector(
    '.sidebarNav-headerMenuContent'
  );
  this.menuContent = this.element.querySelector('.sidebarNav-menuContent');
  this.collapseButton = this.element.querySelector('.js-sidebarNav-collapse');
  this.headerOpenButton = this.element.querySelector(
    '.js-sidebarNav-headerOpen'
  );
  this.headerCloseButton = this.element.querySelector(
    '.js-sidebarNav-headerClose'
  );

  this._handleHeaderMenuToggleClick =
    this._handleHeaderMenuToggleClick.bind(this);
  this._handleCollapseButtonClick = this._handleCollapseButtonClick.bind(this);
  this._handleHeaderOpenButtonClick =
    this._handleHeaderOpenButtonClick.bind(this);
  this._handleHeaderCloseButtonClick =
    this._handleHeaderCloseButtonClick.bind(this);
  this._handleMenuClick = this._handleMenuClick.bind(this);
  this._handleMenuFocus = this._handleMenuFocus.bind(this);
  this._handleMenuItemMouseOver = this._handleMenuItemMouseOver.bind(this);
  this._throttledHandleMenuScroll = rafThrottle(
    this._handleMenuScroll.bind(this)
  );
  this._handleBodyClick = this._handleBodyClick.bind(this);
  this._handleWindowResize = this._handleWindowResize.bind(this);

  this._init();
}

SidebarNav.prototype._init = function _init() {
  this._handleWindowResize(true);
  this._initListeners();
  this._initTransition();
};

SidebarNav.prototype._isHeaderMenuOpened = function _isHeaderMenuOpened() {
  if (!this.headerMenu) {
    return false;
  }
  return classes(this.headerMenu).contains('sidebarNav-headerMenu--opened');
};

SidebarNav.prototype._getCurrentMenuContent =
  function _getCurrentMenuContent() {
    return this._isHeaderMenuOpened()
      ? this.headerMenuContent
      : this.menuContent;
  };

SidebarNav.prototype._restoreCollapsedState =
  function _restoreCollapsedState() {
    if (this.rememberCollapsedStateValue === 'collapsed') {
      this.collapse();
    } else if (this.rememberCollapsedStateValue === 'uncollapsed') {
      this.uncollapse();
    }
  };

SidebarNav.prototype._initTransition = function _initTransition() {
  // eslint-disable-next-line no-unused-expressions
  this.element.offsetHeight;
  classes(this.element).add('sidebarNav--withTransition');
};

SidebarNav.prototype._initListeners = function _initListeners() {
  if (this.headerMenuToggle) {
    this._addListener(
      this.headerMenuToggle,
      'click',
      this._handleHeaderMenuToggleClick
    );
  }

  if (this.headerOpenButton) {
    this._addListener(
      this.headerOpenButton,
      'click',
      this._handleHeaderOpenButtonClick
    );
  }

  if (this.headerCloseButton) {
    this._addListener(
      this.headerCloseButton,
      'click',
      this._handleHeaderCloseButtonClick
    );
  }

  if (this.collapseButton) {
    this._addListener(
      this.collapseButton,
      'click',
      this._handleCollapseButtonClick
    );
  }

  this._addListener(this.element, 'focusin', this._handleMenuFocus);
  this._addListener(this.element, 'click', this._handleMenuClick);
  this._addListener(this.element, 'mouseover', this._handleMenuItemMouseOver);
  this._addListener(
    this.menuContent,
    'scroll',
    this._throttledHandleMenuScroll
  );

  if (this.headerMenuContent) {
    this._addListener(
      this.headerMenuContent,
      'scroll',
      this._throttledHandleMenuScroll
    );
  }

  this._addListener(window.document, 'click', this._handleBodyClick);
  this._addListener(window.document, 'touchstart', this._handleBodyClick);
  this.viewportMonitor.on('resize', this._handleWindowResize);
};

SidebarNav.prototype._resetFocus = function _resetFocus() {
  document.activeElement.blur();
  this.element.focus();
};

SidebarNav.prototype._addListener = function _addListener(element, event, fn) {
  element.addEventListener(event, fn);
  this.listeners.push(element, event, fn);
};

SidebarNav.prototype._handleHeaderMenuToggleClick =
  function _handleHeaderMenuToggleClick(event) {
    if (!this.headerMenu) {
      return;
    }
    if (classes(this.headerMenu).contains('sidebarNav-headerMenu--opened')) {
      classes(this.headerMenu).remove('sidebarNav-headerMenu--opened');
      event.preventDefault();
      this._resetFocus();
    } else {
      classes(this.headerMenu).add('sidebarNav-headerMenu--opened');
      this._closeAllSubmenus();
    }
  };

SidebarNav.prototype._fixTooltipPosition = function _fixTooltipPosition(item) {
  if (closest(item, '.sidebarNav-submenu', true)) {
    return;
  }

  var itemNameWrapper = item.querySelector('.sidebarNav-itemNameWrapper');
  if (itemNameWrapper) {
    var centerFactor = Math.round(
      (item.offsetHeight - itemNameWrapper.offsetHeight) / 2
    );
    itemNameWrapper.style.top =
      item.getBoundingClientRect().top + centerFactor + 'px';
  }
};

SidebarNav.prototype._fixSubmenuPosition = function _fixSubmenuPosition(
  submenuContainer
) {
  var submenuMainItem = submenuContainer.querySelector('.sidebarNav-item');
  var submenu = submenuContainer.querySelector('.sidebarNav-submenu');
  if (submenuMainItem && submenu) {
    var containerHeight = this.element.offsetHeight;
    var submenuHeight = submenu.offsetHeight;
    var submenuTop = submenuMainItem.getBoundingClientRect().top;
    var belowContainer = containerHeight - (submenuTop + submenuHeight);

    if (belowContainer < 0) {
      submenuTop += belowContainer - 10;
    }

    submenu.style.top = submenuTop + 'px';
  }
};

SidebarNav.prototype._handleMenuFocus = function _handleMenuFocus(e) {
  if (!this._isCollapsed()) {
    return;
  }

  var submenuContainer = closest(e.target, '.sidebarNav-withSubmenu', true);
  if (submenuContainer) {
    this._setSubmenuDisplayProperty(submenuContainer);
    this._fixSubmenuPosition(submenuContainer);
  }
};

SidebarNav.prototype._handleMenuClick = function _handleMenuClick(e) {
  var submenuContainer = closest(e.target, '.sidebarNav-withSubmenu', true);
  if (submenuContainer) {
    this._setSubmenuDisplayProperty(submenuContainer);

    if (classes(submenuContainer).contains('sidebarNav-withSubmenu--opened')) {
      classes(submenuContainer).remove('sidebarNav-withSubmenu--opened');
      e.preventDefault();
      this._resetFocus();
    } else {
      classes(submenuContainer).add('sidebarNav-withSubmenu--opened');
    }
  }

  if (this.closeAfterItemPick && closest(e.target, 'a', true)) {
    this._closeAllSubmenus();

    if (this.currentView === mediaQueries.mqSmallOnly) {
      this._clearBodyClasses();
    } else if (this._isHeaderMenuOpened()) {
      if (this.headerMenu) {
        classes(this.headerMenu).remove('sidebarNav-headerMenu--opened');
      }
      this._resetFocus();
    }
  }
};

SidebarNav.prototype._handleMenuItemMouseOver =
  function _handleMenuItemMouseOver(e) {
    if (!this._isCollapsed()) {
      return;
    }

    var item = closest(e.target, '.sidebarNav-item', true);
    if (item) {
      this._fixTooltipPosition(item);
    }

    var submenuContainer = closest(e.target, '.sidebarNav-withSubmenu', true);
    if (submenuContainer) {
      this._setSubmenuDisplayProperty(submenuContainer);
      this._fixSubmenuPosition(submenuContainer);
    }
  };

SidebarNav.prototype._handleMenuScroll = function _handleMenuScroll() {
  if (this._isCollapsed()) {
    var hoveredItem = this._getCurrentMenuContent().querySelector(
      '.sidebarNav-item:hover'
    );
    if (hoveredItem) {
      this._fixTooltipPosition(hoveredItem);
    }
    this._closeAllSubmenus();
  }
};

SidebarNav.prototype._handleBodyClick = function _handleBodyClick(event) {
  if (!closest(event.target, '.sidebarNav-headerMenu', true)) {
    var headerMenu = this.element.querySelector('.sidebarNav-headerMenu');
    if (headerMenu) {
      classes(headerMenu).remove('sidebarNav-headerMenu--opened');
    }
  }

  if (!closest(event.target, '.sidebarNav', true)) {
    this._closeAllSubmenus();
  }

  if (
    this.currentView === mediaQueries.mqSmallOnly &&
    !closest(event.target, '.sidebarNav', true)
  ) {
    classes(window.document.body).remove('sidebarNav-uncollapsed');
    this._emitCollapseState();
  }
};

SidebarNav.prototype._clearBodyClasses = function _clearBodyClasses() {
  classes(window.document.body).remove('sidebarNav-uncollapsed');
  classes(window.document.body).remove('sidebarNav-collapsed');
};

SidebarNav.prototype._clearSubmenuMargins = function _clearSubmenuMargins() {
  var submenus = this.element.querySelectorAll(
    '.sidebarNav-itemNameWrapper, .sidebarNav-submenu'
  );
  if (submenus && submenus.length > 0) {
    Array.prototype.forEach.call(submenus, function (submenu) {
      submenu.style.removeProperty('margin-top');
    });
  }
};

SidebarNav.prototype._initLargeView = function _initLargeView() {
  this.currentView = mediaQueries.mqLargeAndUp;
  this._clearBodyClasses();
  this._clearSubmenuMargins();
  if (this.rememberCollapsedState) {
    this._restoreCollapsedState();
  }
  this._emitCollapseState();
};

SidebarNav.prototype._initMediumView = function _initMediumView() {
  this.currentView = mediaQueries.mqMediumOnly;
  this._clearBodyClasses();
  this._clearSubmenuMargins();
  if (this.rememberCollapsedState) {
    this._restoreCollapsedState();
  } else {
    this.collapse();
  }
  this._emitCollapseState();
};

SidebarNav.prototype._initSmallView = function _initSmallView() {
  this.currentView = mediaQueries.mqSmallOnly;
  this._clearBodyClasses();
  this._clearSubmenuMargins();
  this._emitCollapseState();
};

SidebarNav.prototype._emitCollapseState = function _emitCollapseState() {
  if (this._isCollapsed()) {
    this.emit('collapse', this.currentView);
  } else {
    this.emit('uncollapse', this.currentView);
  }
};

SidebarNav.prototype._handleWindowResize = function _handleWindowResize(force) {
  if (
    this.viewportMonitor.mq(mediaQueries.mqLargeAndUp) &&
    (force || this.currentView !== mediaQueries.mqLargeAndUp)
  ) {
    this._initLargeView();
  } else if (
    this.viewportMonitor.mq(mediaQueries.mqMediumOnly) &&
    (force || this.currentView !== mediaQueries.mqMediumOnly)
  ) {
    this._initMediumView();
  } else if (
    this.viewportMonitor.mq(mediaQueries.mqSmallOnly) &&
    (force || this.currentView !== mediaQueries.mqSmallOnly)
  ) {
    this._initSmallView();
  }
};

SidebarNav.prototype._handleCollapseButtonClick =
  function _handleCollapseButtonClick(event) {
    event.preventDefault();

    if (this._isCollapsed()) {
      this.uncollapse();
    } else {
      this.collapse();
    }

    if (this.rememberCollapsedState) {
      this.rememberCollapsedStateValue = this._isCollapsed()
        ? 'collapsed'
        : 'uncollapsed';
      window.localStorage.setItem(
        'waveSidebarNavCollapsedState',
        this.rememberCollapsedStateValue
      );
    }

    this._emitCollapseState();
  };

SidebarNav.prototype._handleHeaderOpenButtonClick =
  function _handleHeaderOpenButtonClick(event) {
    event.preventDefault();
    classes(window.document.body).add('sidebarNav-uncollapsed');
    this._emitCollapseState();
  };

SidebarNav.prototype._handleHeaderCloseButtonClick =
  function _handleHeaderCloseButtonClick(event) {
    event.preventDefault();
    classes(window.document.body).remove('sidebarNav-uncollapsed');
    this._resetFocus();
    this._emitCollapseState();
  };

SidebarNav.prototype._closeAllSubmenus = function _closeAllSubmenus() {
  var submenuContainers = this.element.querySelectorAll(
    '.sidebarNav-withSubmenu'
  );
  if (submenuContainers && submenuContainers.length > 0) {
    var that = this;
    Array.prototype.forEach.call(
      submenuContainers,
      function (submenuContainer) {
        classes(submenuContainer).remove('sidebarNav-withSubmenu--opened');
        that._setSubmenuDisplayProperty(submenuContainer, true);
      }
    );
  }

  if (
    document.activeElement &&
    closest(document.activeElement, '.sidebarNav-withSubmenu', true)
  ) {
    this._resetFocus();
  }
};

SidebarNav.prototype._setSubmenuDisplayProperty =
  function _setSubmenuDisplayProperty(submenuContainer, hide) {
    var submenu = submenuContainer.querySelector('.sidebarNav-submenu');
    if (submenu) {
      if (hide) {
        submenu.style.display = 'none';
      } else {
        submenu.style.removeProperty('display');
      }
    }
  };

SidebarNav.prototype._isCollapsed = function _isCollapsed() {
  if (classes(window.document.body).contains('sidebarNav-collapsed')) {
    return true;
  }

  if (classes(window.document.body).contains('sidebarNav-uncollapsed')) {
    return false;
  }

  return this.currentView === mediaQueries.mqSmallOnly;
};

SidebarNav.prototype.collapse = function collapse() {
  classes(window.document.body).remove('sidebarNav-uncollapsed');
  classes(window.document.body).add('sidebarNav-collapsed');
  this._closeAllSubmenus();
};

SidebarNav.prototype.uncollapse = function uncollapse() {
  classes(window.document.body).remove('sidebarNav-collapsed');
  classes(window.document.body).add('sidebarNav-uncollapsed');
  this._clearSubmenuMargins();
  this._closeAllSubmenus();
};

SidebarNav.prototype._removeListeners = function _removeListeners() {
  this.listeners.forEach(function (listener) {
    listener[0].removeEventListener(listener[1], listener[2]);
  });
};

SidebarNav.prototype.destroy = function destroy() {
  this._removeListeners();
};
