/**
 * Delay a function call
 */
export default function delay(time) {
  time = time || 10;

  return function (fn) {
    var args = arguments;

    setTimeout(function () {
      fn.apply(this, args);
    }, time);
  };
}
