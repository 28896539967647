import classes from 'component-classes';
import domify from 'domify';
import Emitter from 'emitter-component';
import inheritPrototype from 'mout/lang/inheritPrototype';
import deepMixIn from 'mout/object/deepMixIn';

import Overlay from '../overlay/overlay';
import template from './template';
import templateInline from './template-inline';

export default function createSpinner(el, options) {
  return new Spinner(el, options);
}

var defaults = {
  color: 'accent120',
  inline: false
};

function Spinner(el, options) {
  if (!el) {
    throw new Error('Wave.Spinner() requires a DOM element to initialize');
  }

  Emitter.call(this);

  this.options = deepMixIn({}, defaults, options);
  this._appended = false;
  this._inline = this.options.inline;
  this.el = el;
}

inheritPrototype(Spinner, Emitter);

Spinner.prototype.overlay = function (options) {
  // Prevent appending the overlay if spinner is inline
  if (this._inline) {
    return this;
  }

  var overlayOptions = deepMixIn({}, { target: this.el }, options);

  var _this = this;
  var overlay = Overlay(overlayOptions);

  this.on('show', function () {
    overlay.show();
  });

  this.on('hide', function () {
    overlay.hide();
  });

  overlay.on('click', function () {
    _this.hide();
  });

  this._overlay = overlay;

  return this;
};

Spinner.prototype.show = function () {
  if (!this.el || this._appended) {
    return;
  }

  if (!this._inline) {
    this.spinner = domify(template);
    classes(this.spinner).add(this.options.color);

    if (this.options.position) {
      this.spinner.style.position = this.options.position;
    }

    if (this.options.offset) {
      if (this.options.offset === '50%') {
        classes(this.spinner).add('tC').add('lC');
      } else {
        this.spinner.style.top = this.options.offset;
      }
    }

    // Append to overlay if overlay is present
    if (this._overlay) {
      // this.options.color = 'white';
      this.el = this._overlay.getElement();
    }
  } else {
    this.spinner = domify(templateInline);
    classes(this.spinner).add(this.options.color);
  }

  if (!this.options.insertionType || this.options.insertionType !== 'prepend') {
    this.el.appendChild(this.spinner);
  } else {
    this.el.insertBefore(this.spinner, this.el.firstChild);
  }

  this._appended = true;

  this.emit('show');

  return this;
};

Spinner.prototype.hide = function () {
  if (this._appended) {
    this.spinner.parentNode.removeChild(this.spinner);
    this._appended = false;
  }

  this.emit('hide');

  return this;
};

/**
 * Returns a boolean specifying if the spinner is shown
 */
Spinner.prototype.isShown = function () {
  return this._appended;
};
