/* eslint-disable prettier/prettier */
export default [
  '<span class="multiSelect-item">',
    '<span class="multiSelect-itemLabel"></span>',
    '<button type="button" title="Remove" class="multiSelect-closeButton svgIcon svgIcon--stroked">',
      '<svg viewBox="0 0 24 24" aria-hidden="true" focusable="false">',
        '<path d="M6 18L18 6M6 6l12 12" />',
      '</svg>',
    '</button>',
  '</span>'
].join('');
