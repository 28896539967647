import inheritPrototype from 'mout/lang/inheritPrototype';

import Tip from './tip/tip';

export default function createPopover(options) {
  return new Popover(options);
}

function Popover(options) {
  options = options || {};

  Tip.call(this, '<div class="popover-content"></div>', { node: options.node });

  var className = 'popover';
  if (options.popoverClass) {
    className += ' ' + options.popoverClass;
  }

  this.el.className += ' ' + className;
  this.classname = className;

  this.content(options.content);
}

inheritPrototype(Popover, Tip);

Popover.prototype.content = function (content) {
  var contentEl = this.el.querySelector('.popover-content');

  if (typeof content === 'string') {
    contentEl.innerHTML = content;
  } else {
    contentEl.appendChild(content);
  }

  return this;
};
