import eventListener from 'component-event';
import Emitter from 'emitter-component';
import inheritPrototype from 'mout/lang/inheritPrototype';
import isUndefined from 'mout/lang/isUndefined';

import debounce from '../utils/debounce';
import domReady from '../utils/dom-ready';
import hasFeature from '../utils/has-feature';
import _trim from '../utils/trim';

var document = window.document;
var head = document.head;
var debounceTime;

export default function createBreakpointManager(options) {
  return new BreakpointManager(options);
}

function BreakpointManager(options) {
  Emitter.call(this);

  this.options = options || {};

  debounceTime = this.options.debounceTime || 100;

  this._isReady = false;
  this._isStarted = false;
  this._changeEventName = hasFeature('orientationChange')
    ? 'orientationchange'
    : 'resize';

  domReady(this._handleDomReady.bind(this));
}

inheritPrototype(BreakpointManager, Emitter);

BreakpointManager.prototype.currentBP = null;

/**
 * Start listening to window#resize
 */
BreakpointManager.prototype.start = function () {
  this._isStarted = true;
  this._boundChange = debounce(this._change.bind(this), debounceTime);
  eventListener.bind(window, this._changeEventName, this._boundChange);

  return this;
};

/**
 * Stop listening to window#resize
 */
BreakpointManager.prototype.stop = function () {
  this._isStarted = false;

  if (this._boundChange) {
    eventListener.unbind(window, this._changeEventName, this._boundChange);
  }

  return this;
};

/**
 * Fetch all breakpoints
 */
BreakpointManager.prototype.getAll = function () {
  var BPs = [];
  var allBP = parseMQ(document.querySelector('title')).split(',');

  for (var i = 0; i < allBP.length; i++) {
    BPs.push(_trim(allBP[i]));
  }

  return this._isReady ? BPs : null;
};

/**
 * Fetch the breakpoint in use
 */
BreakpointManager.prototype.getCurrent = function (callback) {
  var nowBP = parseMQ(head);

  return this._isReady
    ? isUndefined(callback)
      ? nowBP
      : callback(nowBP)
    : null;
};

/**
 * Fire events whenever the breakpoint changes
 */
BreakpointManager.prototype._change = function () {
  var _this = this;

  this.getCurrent(function (bp) {
    if (bp !== _this.currentBP) {
      _this.emit(bp);
      _this.emit('change', bp);
      _this.currentBP = bp;
    }
  });
};

BreakpointManager.prototype._handleDomReady = function () {
  this._isReady =
    window.getComputedStyle(head, null).getPropertyValue('clear') !== 'none';
  this._isStarted && this._change();
};

function parseMQ(el) {
  var str = window.getComputedStyle(el, null).getPropertyValue('font-family');
  return str.replace(/"/g, '').replace(/'/g, '');
}
