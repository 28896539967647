/**
 * RequestAnimationFrame polyfill
 */

import detect from './detect';

var requestAnimationFrame = detect('requestAnimationFrame', ['webkit', 'moz']);

var lastTime = 0;

requestAnimationFrame =
  requestAnimationFrame ||
  function (callback, element) {
    var currTime = new Date().getTime();
    var timeToCall = Math.max(0, 16 - (currTime - lastTime));
    var id = window.setTimeout(function () {
      // eslint-disable-next-line n/no-callback-literal
      callback(currTime + timeToCall);
    }, timeToCall);
    lastTime = currTime + timeToCall;
    return id;
  };

export default requestAnimationFrame;
