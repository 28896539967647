/**
 * Trims string whitespace.
 */

export default function trim(str) {
  if (str.trim) {
    return str.trim();
  }

  return str.replace(/^\s*|\s*$/g, '');
}
